
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { NavBar } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
  },
})
export default class HotelRim extends Vue {
  public GDLongitude: number = null;
  public GDLatitude: number = null;
  public HotelName = "";
  public map = null;
  getMapData() {
    let that = this;
    that.map = new AMap.Map("container", {
      center: [that.GDLongitude, that.GDLatitude],
      viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
      zoom: 15, // 初始化地图层级
    });
    let marker = new AMap.Marker({
      position: new AMap.LngLat(that.GDLongitude, that.GDLatitude),
      icon: require("@/assets/image/facilities_map_icon.png"), // 添加 Icon 图标 URL
      // topWhenClick: true // 鼠标点击时marker是否置顶，默认false
      title: that.HotelName,
    });
    // 设置文本标签;
    marker.setLabel({
      direction: "top",
      offset: new AMap.Pixel(0, -2), // 设置文本标注偏移量
      content: `
          <div class='map_info>
            <div class='info_left'>${that.HotelName}</div>
          </div>`,
    });

    marker.setMap(that.map);
  }

  mounted() {
    console.log(this.$route.query.GDLatitude);
    this.GDLongitude = +this.$route.query.GDLongitude;
    this.GDLatitude = +this.$route.query.GDLatitude;
    this.HotelName = this.$route.query.HotelName + "";
    this.getMapData();
  }
}
